<template>
    <Loading v-if="loading" />
    <div>
        <div class="font-medium mx-6">We do not manage or save your payment information. All payments and payment
            information
            will be managed by
            <span class="font-extrabold"> <a href="https://stripe.com/" target="_blank"> Stripe</a>.</span>
        </div>
        <div class="mx-6 my-4">If you pay today your next payment will be on : {{ nextPayDate }} </div>
        <div class="border-4 rounded-xl border-black mx-4 p-3">
            <StripeElements v-if="stripeLoaded" v-slot="{ elements }" ref="elms" :stripe-key="stripeKey"
                :instance-options="instanceOptions" :elements-options="elementsOptions">
                <StripeElement ref="card" :elements="elements" :options="cardOptions" />
            </StripeElements>
        </div>
        <div class="text-center mt-5 text-base">
            <button class="border-2 px-6 py-1 rapbg text-black font-extrabold rounded-lg" type="button" :disabled='loading'
                @click="pay">PAY</button>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import API from "../../api/http";
import { mapGetters } from "vuex";
import Loading from "./Loading";
export default {
    name: 'StripePayment',
    components: {
        StripeElements,
        StripeElement,
        Loading,
    },
    data() {
        return {
            stripeLoaded: false,
            stripePromise: false,
            stripeKey: process.env.VUE_APP_STRIPE_KEY,
            card: null,
            elms: null,
            instanceOptions: null,
            elementsOptions: null,
            cardOptions: {
                value: {
                    postalCode: null,
                },
            },
            nextPayDate: null,
            paymentMethodId: null,
            loading: false,
        }
    },
    computed: {
       ...mapGetters({ user: "User/user" }),
    },
    created() {
        this.stripePromise = loadStripe(this.stripeKey)
        this.stripePromise.then(() => {
            this.stripeLoaded = true
        }),
            this.nextPayDate = new Date();
        this.nextPayDate.setFullYear(this.nextPayDate.getFullYear() + 1);
        this.nextPayDate = this.nextPayDate.toLocaleString('default', { month: 'long' }) + " " + this.nextPayDate.getFullYear();

        if (this.user.subscription_status == "active" || this.user.type == 'user') {
            this.$router.push({name: 'TopRappers'});
        }
    },
    methods: {
        pay() {
            this.loading = true;
            // Get stripe element
            const cardElement = this.$refs.card.stripeElement

            // Access instance methods, e.g. createToken()
            this.$refs.elms.instance.createPaymentMethod({
                type: 'card',
                card: cardElement,
            }).then((result) => {
                // Handle result.error or result.token
                console.log(result.paymentMethod.id);
                this.paymentMethodId = result.paymentMethod.id;

                API.post("create-checkout-session", { paymentMethodId: this.paymentMethodId })
                    .then((response) => {
                        response.user['token'] = this.user.token;
                        response.user['profile_songs'] = this.user.profile_songs;
                        response.user['artist_songs_count'] = this.user.artist_songs_count;
                        console.log(response.user);
                        API.setUser(response.user).then((res) => {
                            console.log(res);
                            this.loading == false;
                            this.$router.push({ name: 'PurchaseHistory'});
                        })
                    })
            })
        },
    },
}
</script>